exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-customer-stories-tsx": () => import("./../../../src/pages/customer-stories.tsx" /* webpackChunkName: "component---src-pages-customer-stories-tsx" */),
  "component---src-pages-forensic-watermarking-tsx": () => import("./../../../src/pages/forensic-watermarking.tsx" /* webpackChunkName: "component---src-pages-forensic-watermarking-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-live-tsx": () => import("./../../../src/pages/live.tsx" /* webpackChunkName: "component---src-pages-live-tsx" */),
  "component---src-pages-marketplace-tsx": () => import("./../../../src/pages/marketplace.tsx" /* webpackChunkName: "component---src-pages-marketplace-tsx" */),
  "component---src-pages-partnerships-tsx": () => import("./../../../src/pages/partnerships.tsx" /* webpackChunkName: "component---src-pages-partnerships-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-radio-renascenca-tsx": () => import("./../../../src/pages/radio-renascenca.tsx" /* webpackChunkName: "component---src-pages-radio-renascenca-tsx" */),
  "component---src-pages-recorder-tsx": () => import("./../../../src/pages/recorder.tsx" /* webpackChunkName: "component---src-pages-recorder-tsx" */),
  "component---src-pages-security-tsx": () => import("./../../../src/pages/security.tsx" /* webpackChunkName: "component---src-pages-security-tsx" */),
  "component---src-pages-streaming-tsx": () => import("./../../../src/pages/streaming.tsx" /* webpackChunkName: "component---src-pages-streaming-tsx" */),
  "component---src-pages-ultimate-pool-tsx": () => import("./../../../src/pages/ultimate-pool.tsx" /* webpackChunkName: "component---src-pages-ultimate-pool-tsx" */),
  "component---src-pages-vod-tsx": () => import("./../../../src/pages/vod.tsx" /* webpackChunkName: "component---src-pages-vod-tsx" */)
}

